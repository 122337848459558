.page-wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-image: url(../../images/background.jpg);
  background-size: cover;
  background-position: center center;
  &__content {
    max-width:75rem;
  }
}
