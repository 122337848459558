@import "src/imports.scss";

.page-wrapper.home {
    background: none;
}

.home {
    .title-container {
        display:flex;
        align-items: center;
        justify-content: center;
        min-height:100vh;
        width:100%;
        background:url(../../images/background.jpg) no-repeat center center;
        background-size: cover;
        &__logo {
            max-width:18.75rem;
        }
    }

    .about-container {
        display:flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        min-height:100vh;
        &__title {
            text-align:center;
            margin-bottom:3.125rem;
            @include for-tablet-landscape-up {
                margin-bottom:6.25rem;
            }
        }
        &__topics {
            width:100%;
            display:flex;
            flex-direction:column;
            justify-content: space-between;
            align-items: center;
            @include for-tablet-landscape-up {
                flex-direction: row;
                align-items: flex-start;
            }
        }
        &__topic {
            padding:1rem;
            width:18.75rem;
            text-align:center;
            flex:1;
        }
        &__icon {
            font-size:4em;
            margin-bottom:2rem;
        }
    }
}
